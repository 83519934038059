import React, {Component} from 'react';
import config from 'react-reveal/globals';
import Fade from 'react-reveal/Fade';
import Motivated from "./images/motivated.svg";
import Dynamic from "./images/dynamic.svg";
import Confident from "./images/confident.svg";
import EmailIcon from "./images/close-envelope.svg";
import LinkedinIcon from "./images/linkedin-logo.svg";
import InstagramIcon from "./images/instagram.svg";
import GithubIcon from "./images/github-logo.svg";
import Projects  from "./proj.json";
import LazyLoad from 'react-lazy-load';

class Main extends Component {

    _sectionOne: ?HTMLInputElement;
    _sectionTwo: ?HTMLInputElement;
    _sectionThree: ?HTMLInputElement;
    _sectionFour: ?HTMLInputElement;
    _navItem: ?HTMLInputElement;

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            sectionOne: true,
            sectionTwo: false,
            sectionThree: false,
            sectionFour: false,
            burger: false,
            nav: false,
            projects: []
        }
        this.handleClick = this.handleClick.bind(this);
        this.burgerClick = this.burgerClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            let scrollTop = document.documentElement.scrollTop;
            if(scrollTop >= this._sectionOne.offsetTop - 140 && scrollTop < (this._sectionOne.offsetTop - 150) + this._sectionOne.offsetHeight) {
                this.setState({sectionOne: true, sectionTwo: false, sectionThree: false, sectionFour: false});
            } else if (scrollTop >= this._sectionTwo.offsetTop - 140 && scrollTop <  (this._sectionTwo.offsetTop - 140) + this._sectionTwo.offsetHeight) {
                this.setState({sectionOne: false, sectionTwo: true, sectionThree: false, sectionFour: false});
            } else if (scrollTop >= this._sectionThree.offsetTop - 140 && scrollTop <  (this._sectionThree.offsetTop - 140) + this._sectionThree.offsetHeight) {
                this.setState({sectionOne: false, sectionTwo: false, sectionThree: true, sectionFour: false});
            } else if (scrollTop >= this._sectionFour.offsetTop - 140 && scrollTop <  (this._sectionFour.offsetTop - 140) + this._sectionFour.offsetHeight) {
                this.setState({sectionOne: false, sectionTwo: false, sectionThree: false, sectionFour: true});
            }
        });

        window.addEventListener('resize', () => {
            if(window.innerWidth <= 768) {
                this.setState({burger: true});
                this.setState({nav: true});
            } else {
                this.setState({burger: false});
                this.setState({nav: false});
            }
        });

        if(window.innerWidth <= 768) {
            this.setState({burger: true});
            this.setState({nav: true});
        } else {
            this.setState({burger: false});
            this.setState({nav: false});
        }
    }

    handleClick(e, section) {
        e.preventDefault();
        if(section === "sectionOne") {
            window.scrollTo(0, this._sectionOne.offsetTop - 140);
        } else if(section === "sectionTwo") {
            window.scrollTo(0, this._sectionTwo.offsetTop - 140);
        } else if(section === "sectionThree") {
            window.scrollTo(0, this._sectionThree.offsetTop - 140);
        } else if(section === "sectionFour") {
            window.scrollTo(0, this._sectionFour.offsetTop - 140);
        }
    }

    burgerClick(e) {
        e.preventDefault();
        if(this.state.nav) {
            this.setState({nav: false});
        } else {
            this.setState({nav: true});
        }
    }

    render() {
        config({ ssrFadeout: true });
        return (
            <div className="App">
                <nav className="nav">
                    <div className="nav__logo">
                        <div className="nav__logo--first-character">S</div>
                        <div className="nav__logo--second-character">P</div>
                    </div>
                    <ul className={`nav__list ${this.state.nav ? "hide" : "show"}`}>
                        <li className={`nav__list--item ${this.state.sectionOne ? "active" : "none"}`} onClick={(e) => this.handleClick(e, "sectionOne")}>Home</li>
                        <li className={`nav__list--item ${this.state.sectionTwo ? "active" : "none"}`} onClick={(e) => this.handleClick(e, "sectionTwo")}>About</li>
                        <li className={`nav__list--item ${this.state.sectionThree ? "active" : "none"}`} onClick={(e) => this.handleClick(e, "sectionThree")}>Projects</li>
                        <li className={`nav__list--item ${this.state.sectionFour ? "active" : "none"}`} onClick={(e) => this.handleClick(e, "sectionFour")}>Contact Me</li>
                        <a href="https://reactimages.blob.core.windows.net/images/resume.pdf" target="_resume"><li className="nav__list--item">Resume</li></a>
                    </ul>
                    <div className={`nav__burger ${this.state.burger ? "show" : "hide"}`} onClick={this.burgerClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </nav>
                <div className="main">
                    <header className="header" ref={c => (this._sectionOne = c)} id="sectionOne">
                        <Fade bottom ssrFadeout>
                            <div className="header__title">
                                I am Sholomon Pinoliad
                                <div className="header__title--subtitle">Motivated. Dynamic. Confident</div>
                            </div>
                        </Fade>
                        <div className="header__cover" style={{backgroundImage: `url('https://reactimages.blob.core.windows.net/images/cover-photo.png')`}}></div>
                    </header>
                    <section ref={c => (this._sectionTwo = c)} id="sectionTwo">
                        <div className="container">
                            <div className="container__half">
                                <Fade bottom ssrFadeout>
                                    <div className="container__half--svg" style={{backgroundImage: `url(${Motivated})`}}></div>
                                </Fade>
                            </div>
                            <div className="container__half">
                                <div className="container__half--content">
                                    <Fade right ssrFadeout>
                                        <h1>Motivated</h1>
                                        <p>Motivated in making things possible by turning visions into reality.</p>
                                    </Fade>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="container__half">
                                <Fade bottom ssrFadeout>
                                    <div className="container__half--svg" style={{backgroundImage: `url(${Dynamic})`}}></div>
                                </Fade>
                            </div>
                            <div className="container__half">
                                <div className="container__half--content">
                                    <Fade right ssrFadeout>
                                        <h1>Dynamic</h1>
                                        <p>I am a positive and energetic person who continuously learn something new.</p>
                                    </Fade>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="container__half">
                                <Fade bottom ssrFadeout>
                                    <div className="container__half--svg" style={{backgroundImage: `url(${Confident})`}}></div>
                                </Fade>
                            </div>
                            <div className="container__half">
                                <div className="container__half--content">
                                    <Fade right ssrFadeout>
                                        <h1>Confident</h1>
                                        <p>Confident enough with my skills to empower every person.</p>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <div className="container__information">
                            <div className="curve-top"></div>
                            <Fade bottom ssrFadeout>
                                <div className="container__information--text">
                                    A graduating student of Lyceum of the Philippines University - Manila taking Bachelor of Science in Information Technology, and a student leader currently leading the university-wide organization - LPU Manila Microsoft Student Community - as Execute Vice President.
                                </div>
                            </Fade>
                        </div>
                    </section>
                    <section ref={c => (this._sectionThree = c)} id="sectionThree">
                        <div className="container">
                            {Projects.map((data, index) => {
                                return (
                                    <div key={index}>
                                        <Fade right ssrFadeout>
                                            <div className="container__project">
                                                <div className="container__project--content">
                                                    <div className="container__project--content__image" style={{backgroundImage: `url(${data.thumb})`}}></div>
                                                    <div className="container__project--content__title">{data.name}</div>
                                                    <div className="container__project--content__description">
                                                        {data.desc}
                                                    </div>
                                                    <div className="container__project--content__tags">
                                                    {data.tags.map((item, i) => <span key={i}>{item}</span>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                    <section ref={c => (this._sectionFour = c)} id="sectionFour">
                        <div className="container">
                            <Fade bottom ssrFadeout>
                                <div className="container__half">
                                    <h1>Do you have more questions?</h1>
                                    <p>
                                        You can reach me out through my social media accounts or personal email.
                                    </p>
                                </div>
                            </Fade>
                            <Fade right ssrFadeout>
                                <div className="container__half">
                                    <div className="container__half--contact">
                                        <div className="container__half--contact__icon" style={{backgroundImage: `url(${EmailIcon})`}}></div>
                                        <div className="container__half--contact__text">
                                            sholomon.pinoliad@gmail.com
                                        </div>
                                    </div>
                                    <div className="container__half--contact">
                                        <a href="https://www.linkedin.com/in/sholomon">
                                            <div className="container__half--contact__icon" style={{backgroundImage: `url(${LinkedinIcon})`}}></div>
                                            <div className="container__half--contact__text">
                                                /sholomon
                                            </div>
                                        </a>
                                    </div>
                                    <div className="container__half--contact">
                                        <a href="https://www.instagram.com/onemonstar">
                                            <div className="container__half--contact__icon" style={{backgroundImage: `url(${InstagramIcon})`}}></div>
                                            <div className="container__half--contact__text">
                                                @onemonstar
                                            </div>
                                        </a>
                                    </div>
                                    <div className="container__half--contact">
                                        <a href="https://www.github.com/monpinoliad">
                                            <div className="container__half--contact__icon" style={{backgroundImage: `url(${GithubIcon})`}}></div>
                                            <div className="container__half--contact__text">
                                                /monpinoliad
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </section>
                    <footer className="footer">
                        &copy; Copyright 2019. All Rights Reserved. Icons from <a href="https://www.flaticon.com" target="_flaticon">Flaticon</a>.
                    </footer>
                </div>
            </div>
        )
    }
}
export default Main;
